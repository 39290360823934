<template>
  <div class="productDetails" v-bind:class="{ right: imgRight, themeDark: theme == 'dark' }" >
    <div v-if="img" class="productImg">
      <img :src="getImgUrl(img)" :alt="label" />
    </div>
    <div class="productCopy">
      <div v-if="label" class="productLabel">{{ label }}</div>
      <div v-if="priceInfo" class="productPrice">{{ priceInfo }}</div>
      <div class="productDescription">
        <slot />
      </div>
    <div
      v-if="itemId && inStock"
      class="productBtn"
      v-bind:class="{ animatedBtn: addingItem }"
    >
      <a ref="productItemBtn" @click.prevent="buildCartItem()">{{ btnText }}</a>
    </div>
    <div
      v-else-if="coupon && inStock"
      class="productBtn"
      v-bind:class="{ animatedBtn: addingItem }"
    >
      <a ref="productCouponBtn" @click.prevent="buildCartCoupon()">{{
        btnText
      }}</a>
    </div>
      <div class="out" v-if="!inStock">Out of Stock</div>
      <div class="checkoutLink" v-if="itemAdded">
        <router-link to="/cart">View Cart</router-link>
      </div>
      <!--END itemAdded-->
    </div>
  </div>
  <!-- END productDetails -->
</template>

<script>
import { IMAGES_URL } from "@/constants/";
import { mapState } from "vuex";
import Vue from 'vue';
export default {
  name: "ProductDetails",
  data: function () {
    return {
      addingItem: false,
      itemAdded: false,
      priceInfo: null,
      inStock: false,
      outOfStockMessage: 'Out of Stock'
    };
  },
  props: {
    label: { type: String },
    img: { type: String },
    imgRight: { type: Boolean, default: false },
    itemId: { type: Number },
    promo: { type: String },
    coupon: { type: String },
    btnLabel: { type: String, default: "Add to Cart" },
    theme: { type: String },
  },
  computed: {
    ...mapState({
      currentCart: (state) => state.cart,
    }),
    btnText: function () {
      if (this.addingItem) {
        return "Adding To Cart";
      } else {
        return this.btnLabel;
      }
    },
  },
  mounted () {
    
    if(this.promo) {
      Vue.axios
        .get(process.env.VUE_APP_API_ROOT + 'cart/gaming/bundle/' + this.promo)
        .then((response) => {
          let itemsTotal = 0;
          let promoDiscount= response.data.promoDiscount;

          // loop through promo items
          response.data.promoItems.forEach((item) => {

            // Calculating total price
            itemsTotal += item.unitPrice;

          });

          //Apply the discount to the items total
          this.priceInfo = "$" + (itemsTotal - promoDiscount).toFixed(2);

          // Setting store status - items may be out of stock
          if(response.data.status == "active") {
            this.inStock = true;
          }
        });

    }
    if(this.itemId) {
      
      Vue.axios
        .get(process.env.VUE_APP_API_ROOT + 'product/store/' + this.itemId)
        .then((response) => {
          this.priceInfo = "$" + response.data.price.toFixed(2);

          // Setting store status
          if(response.data.statusStore == "in-stock") {
            this.inStock = true;
          }else if(response.data.statusStore == "coming-soon") {
            this.outOfStockMessage = 'Coming Soon';
          }else if(response.data.statusStore == "discontinued") {
            this.outOfStockMessage = "Discontinued";
          }else if(response.data.statusStore == "preorder") {
            this.outOfStockMessage = "Preorder";
          }else if(response.data.statusStore == "call-to-order") {
            this.outOfStockMessage = "Call to Order";
          }else{
            this.outOfStockMessage = "Error";
          }
        });

    }

  },
  methods: {
    getImgUrl(pic) {
      return IMAGES_URL + pic;
    },
    buildCartItem() {
      var cartItem = {
        cart_id: this.currentCart.id,
        product_id: this.itemId,
        quantity: 1,
        options: [],
      };
      this.$store
        .dispatch("addToCartAction", cartItem)
        .then(() => {
          this.addingItem = true;
        })
        .then(() => {
          setTimeout(() => {
            this.addingItem = false;
            this.itemAdded = true;
          }, 2000);
        });
    },
    buildCartCoupon() {
      this.$store
        .dispatch("addBundleToCartAction", { hash: this.coupon, quanity: 1 })
        .then(() => {
          this.addingItem = true;
        })
        .then(() => {
          setTimeout(() => {
            this.addingItem = false;
            this.itemAdded = true;
          }, 2000);
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.productDetails {
  text-align: left;
  align-self: end;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;

  @include for-medium-up {
    flex-direction: row;
  }

  &.right {
    flex-direction: row-reverse;

    .productCopy {
      padding-left: 0px;
      padding-right: 10px;
    }
  }

  .productImg {
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;
    text-align: center;
    img {
      width: auto;
      max-width: 100%;
    }

    @include for-medium-up {
      width: 50%;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .productCopy {
    width: 100%;
    box-sizing: border-box;
    padding-left: 10px;
    position: relative;

    @include for-medium-up {
      width: 50%;
    }
  }
  .productLabel {
    display: block;
    margin: 10px 0px;
    font-family: $font-stack-heading;
    color: #ffffff;
    font-size: 32px;
  }
  .productPrice {
    display: inline-block;
    font-family: $font-stack-heading;
  }
  .productDescription {
    margin: 10px 0px;
  }
  .productBtn {
    margin-top: 50px;
    text-align: center;
    width: 220px;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
    }
  }
  .checkoutLink a {
    position: absolute;
    width: 220px;
    text-align: center;
    padding-top: 10px;
    text-decoration: underline;
    animation-duration: 0.5s;
    animation-name: slideDown;

    &:hover {
      color: #ffffff;
    }
  }
}
.out{
  padding:12px 54px;
  margin:0 2px;
}
.productBtn a {
  @include btn();
  overflow: hidden;
  color: #fff;
  cursor: pointer;
  display: block;
  position: relative;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  
}

.productBtn.animatedBtn a {
  color: #ffffff;
  background-color: transparent;
  text-shadow: none;
}
.productBtn.animatedBtn a:before {
  bottom: 0%;
  top: auto;
  height: 100%;
}
.productBtn a:before {
  display: block;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 0px;
  width: 100%;
  z-index: -1;
  content: "";
  background: #000000;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.productDetails.themeDark .productCopy{
  color: rgba(255, 255, 255, 0.5);
}
.productDetails.themeDark .productBtn a{
  border: solid 1px #fff;
  &:hover {
      background: rgba(255, 255, 255, 0.1);
  }
}
.productDetails.themeDark .productLabel {
  display: block;
  font-size: 32px;
}
.productDetails.themeDark .productPrice {
  margin-left: 0px;
  color: #fff;
  font-size: 24px;
}
.productDetails.themeDark .checkoutLink a {
    color: rgba(255, 255, 255, 0.5);

    &:hover {
      color: #fff;
    }
}

@keyframes slideDown {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
